<template>
  <div v-if="!isLoadingModels" class="chat-holder">
    <template v-if="!modelsLoadingFailed">
      <div class="output-holder">
        <Output />
      </div>
      <div class="send-wrapper">
        <Textfield v-model="chatMessage" @keyup="onKeyup" />
        <Button :disabled="!chatMessage.trim().length" @click="sendMessage"> Send </Button>
        <Button color="secondary" @click="clear"> Clear </Button>
      </div>
    </template>
    <template v-else>
      <div>Failed to load models</div>
    </template>
  </div>
  <div v-else class="chat-holder">
    <Loading />
  </div>
</template>

<script>
import Textfield from '@/components/common/TextField';
import Button from '@/components/common/Button';
import Output from './Output.vue';
import { mapState } from 'vuex';
import Loading from '@/components/common/Loading';
export default {
  name: 'Chat',
  components: {
    Textfield,
    Button,
    Output,
    Loading
  },
  data() {
    return {
      chatMessage: ''
    };
  },
  computed: {
    ...mapState({
      prompt: (state) => state.chat.model,
      isLoadingModels: (state) => state.chat.isLoadingModels,
      modelsLoadingFailed: (state) => state.chat.modelsLoadingFailed
    })
  },
  async created() {
    await this.$store.dispatch('chat/getModel');
  },
  methods: {
    async sendMessage() {
      if (!this.prompt) {
        return;
      }
      if (!this.chatMessage.trim()) {
        return;
      }
      const chat = this.prompt.template.chat;
      if (!chat) {
        return;
      }

      this.shouldFocus = true;

      const systemMessage = chat.messages.filter((message) => message.role !== 'user');

      const chatPayload = {
        ...chat,
        messages: [
          ...systemMessage,
          {
            role: 'user',
            content: this.chatMessage
          }
        ]
      };

      try {
        this.chatMessage = '';
        await this.$store.dispatch('chat/sendChatMessage', chatPayload);
      } catch (error) {
        // do nothing
      }
    },
    onKeyup(e) {
      if (e.key === 'Enter') {
        this.sendMessage();
      }
    },
    clear() {
      this.$store.commit('chat/clear');
    }
  }
};
</script>

<style lang="scss" scoped>
.chat-holder {
  display: grid;
  grid-template-rows: 1fr max-content;
  overflow: hidden;
  .output-holder {
    height: 100%;
    overflow: scroll;
  }
}
.send-wrapper {
  display: grid;
  grid-template-columns: 1fr max-content max-content;
}
/* Your component styles here */
</style>
