<template>
  <div>
    <iframe ref="iframe" :srcdoc="htmlContent" frameborder="0" style="width: 100%" @load="adjustIframeSize"></iframe>
  </div>
</template>

<script>
export default {
  name: 'HtmlPreview',
  props: {
    htmlContent: {
      type: String,
      required: true
    }
  },
  methods: {
    adjustIframeSize() {
      const iframe = this.$refs.iframe;
      const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
      const body = iframeDocument.body;
      const svg = iframeDocument.body.querySelector('svg');
      let svgSize;
      if (svg) {
        svgSize = svg.getBoundingClientRect();
      } else {
        const body = iframeDocument.body;
        svgSize = body.getBoundingClientRect();
      }
      iframeDocument.documentElement.style.margin = '0';
      iframeDocument.documentElement.style.padding = '0';
      body.style.margin = '0';
      body.style.padding = '0';

      iframe.style.height = (svgSize?.height || 600) + 20 + 'px';
      iframe.style.width = (svgSize?.width || 600) + 20 + 'px';
    }
  }
};
</script>

<style scoped>
div {
  width: 100%;
  height: 100%;
}
iframe {
  width: 100%;
  border: 2px solid white;
}
</style>
