import { am } from './utils';

import httpClient from '@/utils/httpClient';

function messageReceived(commit, event, data, isChat) {
  switch (event) {
    case 'chain-start': {
      break;
    }
    case 'link-start': {
      commit(MESSAGE_ADDED, data);
      break;
    }
    case 'stream-start': {
      break;
    }
    case 'tool': {
      commit(TOOL_CALL, { event, data });
      break;
    }
    case 'stream': {
      if (data?.content === undefined) {
        throw new Error(`Bad stream data: ${data ? JSON.stringify(data) : undefined}`);
      }

      commit(EXEC_PROGRESS, data?.content);
      break;
    }
    case 'stream-end': {
      if (isChat) {
        commit(SEND_CHAT_MESSAGE.COMPLETED);
      }
      break;
    }
    case 'link-finish': {
      break;
    }
    case 'chain-finish': {
      commit(EXEC.COMPLETED);
      break;
    }
    case 'retry': {
      commit(RETRY_OCCURED, data);
      break;
    }
    case 'error': {
      if (isChat) {
        commit(SEND_CHAT_MESSAGE.FAILED, data);
      } else {
        commit(ERROR_OCCURED, data);
      }
      break;
    }
  }
}

async function execute({ commit, state, getters }) {
  if (!state.chain?.length) {
    return;
  }

  try {
    while (state.cursor && !state.cancelled) {
      const begin = state.cursor;
      const end = state.chain.slice(state.chain.indexOf(begin) + 1).find((link) => link.breakpoint) || state.chain[state.chain.length - 1];
      let form;

      if (begin.type === 'form') {
        form = begin;
      }

      if (form && !state.continue) {
        form.variables = (
          await httpClient.post(`/api/v2/nlp/prompts/variables/resolve`, {
            variables: [...getters.previousRunValues.filter((v) => !form.variables.find((fv) => fv.name === v.name)), ...form.variables]
          })
        ).filter((v) => form.variables.find((fv) => fv.name === v.name));
        commit(MESSAGE_ADDED, form);
        break;
      } else {
        if (form && begin === end) {
          // single form case
          state.cursor = null;
          commit(EXEC.COMPLETED);
          break;
        } else {
          state.continue = false;
          state.cursor = state.chain.indexOf(end) === state.chain.length - 1 ? null : end;

          await httpClient.stream(
            `/api/v2/nlp/chat-stream`,
            {
              body: {
                begin: begin.breakpoint,
                end: end.breakpoint,
                chain: state.chain,
                context: state.context,
                variables: getters.previousRunValues,
                debug: true
              }
            },
            (event, data) => messageReceived(commit, event, data, false)
          );
        }
      }
    }
  } catch (e) {
    try {
      const responseText = await e.response.text();
      const { message } = JSON.parse(responseText);
      commit(EXEC.FAILED, message);
    } catch (e) {
      commit(EXEC.FAILED, 'Unable to generate an output.');
    }
  }
}

const EXEC = am(`EXEC_PROMPT`);

const INITIALIZE = am(`PROMPT_INITIALIZE`);
const GET_MODELS = am(`GET_MODELS`);

const EXEC_PROGRESS = 'EXEC_PROGRESS';
const TOOL_CALL = 'TOOL_CALL';
const MESSAGE_ADDED = 'MESSAGE_ADDED';
const RETRY_OCCURED = 'RETRY_OCCURED';
const ERROR_OCCURED = 'ERROR_OCCURED';
const EXEC_ABORT = 'EXEC_ABORT';
const SEND_CHAT_MESSAGE = am('SEND_CHAT_MESSAGE');

export default {
  namespaced: true,
  state: {
    isRequestPending: false,
    isRequestFailed: false,
    response: [],
    isLoadingModels: false,
    modelsLoadingFailed: false,
    model: null,
    chain: [],
    cancellation: null,
    previousRun: { output: [], forms: [] }
  },
  getters: {
    previousRunValues(state) {
      return [...state.previousRun.output, ...state.previousRun.forms];
    }
  },
  mutations: {
    [INITIALIZE.STARTED](state, chain) {
      state.isRequestPending = false;
      state.isRequestFailed = false;
      state.chain = [];
      state.cancellation = null;
      state.response = [];

      chain = JSON.parse(JSON.stringify(chain));

      const links = chain.filter((l) => l.type !== 'form');
      const forms = chain.filter((l) => l.type === 'form');

      const formVariables = [];

      forms.forEach((f) => {
        formVariables.push(
          ...f.variables.map((v) => ({
            form: f.name,
            ...v
          }))
        );
      });

      state.previousRun = {
        output: links.map((link) => ({
          link: link.type,
          linkName: link.name,
          name: `${link.type.toUpperCase()}_${links.filter((l) => l.type === link.type).indexOf(link) + 1}_OUTPUT`,
          value: ''
        })),
        forms: formVariables
      };
    },
    [EXEC.STARTED](state, prompt) {
      if (state.cancellation) {
        state.cancellation.abort();
      }

      const { context, chain } = JSON.parse(JSON.stringify(prompt));

      let index = 1;
      chain.forEach((link) => {
        if (link.type === 'form') {
          link.breakpoint = `${link.type} ${index}`;
          index++;
        }
      });

      state.cursor = chain[0];
      state.execOne = prompt.execOne || false;
      state.index = prompt.index || 0;
      state.chain = chain;
      state.context = context;
      state.cancellation = new AbortController();
      state.continue = false;
      state.cancelled = false;
      state.isRequestPending = true;
      state.isRequestFailed = false;
      state.response = [];
    },
    [EXEC_PROGRESS](state, response) {
      var message = state.response[state.response.length - 1];

      if (!message) {
        return;
      }

      message.text += response;
    },
    [TOOL_CALL](state, { event, data }) {
      const tool = data.tool;
      if (tool.result) {
        const funcCallMessage = state.response.find((r) => r.callId === tool.callId);
        funcCallMessage.arguments = tool.arguments;
        funcCallMessage.text = tool.content;
        funcCallMessage.result = true;
        funcCallMessage.cache = tool.cache;
      } else {
        state.response.splice(state.response.length - 1, 0, {
          ...tool,
          key: tool.callId,
          type: event,
          author: tool.name,
          expanded: false
        });
      }
    },
    [MESSAGE_ADDED](state, link) {
      let responseMessage;
      let authorMessage;
      switch (link.type) {
        case 'chat':
        case 'prompt':
          {
            const messages = link.messages || link.link?.messages || (link.chain && link.chain[0]?.messages) || [];

            const author = 'Alfred';
            const userMessage = messages.filter((m) => m.role === 'user').slice(-1)[0] ?? messages.find((m) => m.role === 'user');
            const systemMessage = messages.filter((m) => m.role === 'assistant').slice(-1)[0] ?? messages.find((m) => m.role === 'system');
            authorMessage = {
              text: userMessage?.content || userMessage,
              author: 'CURRENT_USER'
            };
            responseMessage = {
              text: '',
              name: link.name,
              type: link.type,
              author: author,
              system: systemMessage.content,
              retry: false,
              error: false
            };
          }
          break;
        case 'function':
          {
            const author = link.model;
            authorMessage = {
              text: `Execute query '${link.name?.toUpperCase()}'`,
              author: 'CURRENT_USER'
            };
            responseMessage = {
              text: '',
              name: link.name,
              type: link.type,
              author
            };
          }
          break;
        case 'query':
          {
            const author = link.model;
            authorMessage = {
              text: `Execute query '${link.name?.toUpperCase()}'`,
              author: 'CURRENT_USER'
            };
            responseMessage = {
              text: '',
              name: link.name,
              type: link.type,
              author
            };
          }
          break;
        case 'template': {
          const author = link.model;
          authorMessage = {
            text: `Execute template '${link.name?.toUpperCase()}'`,
            author: 'CURRENT_USER'
          };
          responseMessage = {
            text: '',
            name: link.name,
            type: link.type,
            author
          };
          break;
        }
        case 'form':
          {
            authorMessage = {
              ...link,
              done: false
            };
          }
          break;
      }
      state.response.push({ key: Math.floor(Math.random() * 1000000), ...authorMessage });
      if (responseMessage) {
        state.response.push({ key: Math.floor(Math.random() * 1000000), ...responseMessage });
      }
    },
    [RETRY_OCCURED](state, event) {
      state.response[state.response.length - 1].retry = true;
      state.response[state.response.length - 1].text = 'An error occured. Retrying...';

      if (event.type === 'prompt') {
        const systemMessage = event.messages.find((m) => m.role === 'system');
        state.response.push({
          text: '',
          author: event.model,
          system: systemMessage.content,
          retry: false,
          error: false
        });
      } else {
        state.response.push({
          text: '',
          author: event.model,
          system: 'Failed to execute query',
          retry: false,
          error: false
        });
      }
    },
    [ERROR_OCCURED](state, event) {
      let prompt = state.response[state.response.length - 1];
      if (!prompt) {
        prompt = {
          author: 'Unknown',
          text: `ERROR: ${event}`,
          error: true
        };
        state.response.push(prompt);
      }
      prompt.text = event;
      prompt.error = true;
    },
    [EXEC.COMPLETED](state) {
      const modelResponses = state.response.filter((m) => m.author !== 'CURRENT_USER');

      // executing one link from a chain
      if (state.execOne) {
        const running = state.chain[0];
        if (running.link !== 'form' && running.type !== 'form') {
          const output = state.previousRun.output[state.index];
          if (output) {
            output.value = modelResponses[0].text;
          } else {
            state.previousRun.output.push({
              linkType: running.type,
              linkName: running.name,
              name: `${running.type.toUpperCase()}_${state.chain.filter((l) => l.type === running.type).indexOf(running) + 1}_OUTPUT`,
              value: ''
            });
          }
        } else {
          for (const variable of running.variables) {
            const form = state.previousRun.forms.find((fv) => fv.name === variable.name);
            if (form) {
              form.value = variable.value;
            } else {
              state.previousRun.forms.push({
                linkType: 'form',
                linkName: running.name,
                name: variable.name,
                value: variable.value
              });
            }
          }
        }
      } else {
        // running the whole chain
        modelResponses
          .filter((r) => r.type != 'form')
          .map((response) => ({
            linkType: response.type,
            linkName: response.name,
            name: `${response.type.toUpperCase()}_${modelResponses.filter((l) => l.type === response.type).indexOf(response) + 1}_OUTPUT`,
            value: response.text
          }))
          .forEach((newValue) => {
            const existingValue = state.previousRun.output.find((o) => o.name === newValue.name);
            if (existingValue) {
              existingValue.value = newValue.value;
            } else {
              state.previousRun.output.push(newValue);
            }
          });

        const forms = [];
        modelResponses
          .filter((r) => r.type === 'form')
          .forEach((f) => {
            f.variables.forEach((v) =>
              forms.push({
                linkType: f.type,
                linkName: f.name,
                name: v.name,
                value: v.value
              })
            );
          });

        forms.forEach((newValue) => {
          const existingValue = state.previousRun.forms.find((o) => o.name === newValue.name);
          if (existingValue) {
            existingValue.value = newValue.value;
          } else {
            state.previousRun.forms.push(newValue);
          }
        });
      }

      // current run is not finished
      if (state.cursor) {
        return;
      }

      // current run is finished
      state.isRequestPending = false;
      state.cancellation?.abort();
    },
    [EXEC_ABORT](state) {
      state.isRequestPending = false;
      state.cancellation?.abort();
      state.cancelled = true;
    },
    [EXEC.FAILED](state) {
      state.isRequestPending = false;
      state.isRequestFailed = true;
      state.response = [];
      state.cancellation?.abort();
    },
    [GET_MODELS.STARTED](state) {
      state.isLoadingModels = true;
    },
    [GET_MODELS.COMPLETED](state, model) {
      state.isLoadingModels = false;
      state.model = model;
    },
    [GET_MODELS.FAILED](state) {
      state.isLoadingModels = false;
      state.modelsLoadingFailed = true;
    },
    [SEND_CHAT_MESSAGE.STARTED](state) {
      state.isRequestPending = true;
      state.isRequestFailed = false;
      state.cancellation = new AbortController();
    },
    [SEND_CHAT_MESSAGE.COMPLETED](state) {
      state.isRequestPending = false;
      state.cancellation?.abort();
    },
    [SEND_CHAT_MESSAGE.FAILED](state) {
      state.isRequestPending = false;
      state.isRequestFailed = true;
      state.cancellation?.abort();
    },
    clear(state) {
      state.response = [];
    }
  },
  actions: {
    async exec({ commit, state, getters }, prompt) {
      commit(EXEC.STARTED, prompt);
      await execute({ commit, state, getters });
    },
    async continue({ commit, state, getters }) {
      state.continue = true;
      await execute({ commit, state, getters });
    },
    reset({ commit }, chain) {
      commit(INITIALIZE.STARTED, chain);
    },
    async closeStream({ commit }) {
      commit(EXEC_ABORT);
    },
    async sendChatMessage({ commit, state }, chatPayload) {
      commit(SEND_CHAT_MESSAGE.STARTED);
      let history = [];
      const images = [];
      state.response?.forEach((response) => {
        if (response.type === 'form' && response.variables.length) {
          response.variables.forEach((variable) => {
            if (variable.type === 'image' && variable.value.length) {
              variable.value.forEach((value) => {
                images.push({
                  type: 'image_url',
                  imageUrl: {
                    url: value.base64
                  }
                });
              });
            }
          });
        } else if (response.type === 'tool' && response.cache) {
          history.push({
            role: 'function',
            name: response.author,
            content: response.text
          });
        } else {
          history.push({
            role: ['CURRENT_USER', 'template-generator', 'hub-vectorizer'].includes(response.author) ? 'user' : 'assistant',
            content: response.text
          });
        }
      });

      commit(MESSAGE_ADDED, chatPayload);

      const systemMesage = chatPayload.messages.find((m) => m.role === 'system');
      let userMessage = chatPayload.messages.find((m) => m.role === 'user');

      if (images.length) {
        userMessage = {
          role: 'user',
          content: [
            ...images,
            {
              type: 'text',
              content: userMessage.content
            }
          ]
        };
      }

      chatPayload.messages = [systemMesage, ...history, userMessage];

      try {
        await httpClient.stream(
          '/api/v2/nlp/chat-stream',
          {
            body: chatPayload
          },
          (event, data) => messageReceived(commit, event, data, true)
        );
      } catch (error) {
        commit(SEND_CHAT_MESSAGE.FAILED, error);
        throw error;
      }
    },
    async getModel({ commit }) {
      try {
        commit(GET_MODELS.STARTED);
        const { data } = await httpClient.get('/api/prompts/?tags=searchr');
        if (data.length > 0) {
          const modelId = data[0].id;
          const prompt = await httpClient.get(`/api/prompts/${modelId}`);
          commit(GET_MODELS.COMPLETED, prompt);
        } else {
          commit(GET_MODELS.FAILED);
        }
      } catch (e) {
        commit(GET_MODELS.FAILED);
      }
    }
  }
};
