<template>
  <div class="shell-wrapper" :class="[chatMode]">
    <section class="shell">
      <router-view></router-view>
    </section>
    <div class="chat-wrapper">
      <div class="header">
        <span>Chat with Alfred</span>
        <div>
          <Button v-if="chatModeIndex < chatModes.length - 1" class="toggle-expand" variant="contained" @click="chatModeIndex++">
            <Icon name="chevron-up" />
          </Button>
          <Button v-if="chatModeIndex > 0" class="toggle-expand" variant="contained" @click="chatModeIndex--">
            <Icon name="chevron-down" />
          </Button>
        </div>
      </div>
      <Chat />
    </div>
  </div>
</template>

<script>
import Button from '@/components/common/Button';
import Icon from '@/components/common/Icon';
import Chat from './Chat';
export default {
  name: 'Shell',
  components: {
    Chat,
    Button,
    Icon
  },
  data() {
    return {
      chatModes: ['minimized', 'half', 'expanded'],
      chatModeIndex: 0
    };
  },
  computed: {
    chatMode() {
      return this.chatModes[this.chatModeIndex];
    }
  }
};
</script>

<style lang="scss" scoped>
.shell-wrapper {
  display: grid;
  // grid-template-rows: 1fr 1fr;
  height: 100%;
  overflow: hidden;

  &.minimized {
    grid-template-rows: 1fr 35px;
  }

  &.half {
    grid-template-rows: 1fr 1fr;
  }
  &.expanded {
    grid-template-rows: 0 1fr;
  }

  .chat-wrapper {
    display: grid;
    grid-template-rows: max-content 1fr;
    overflow: hidden;
    border-top: 2px solid var(--theme-primary);
    .header {
      display: flex;
      border-bottom: 1px solid var(--theme-primary);

      padding: 0.5rem;
      .toggle-expand {
        padding: 0;
      }
    }
  }

  .shell {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    width: 100%;

    overflow: scroll;

    aside {
      color: var(--theme-on-surface);
      background-color: var(--theme-surface);
      width: 50px;
      overflow: hidden;
      display: grid;
      grid-template-rows: minmax(0, 1fr) 30px;
      nav {
        overflow: hidden;
      }
      .nav-list {
        display: grid;
        font-size: 1rem;
        padding: 1rem;
        grid-gap: 0.5rem;
        align-items: center;

        .nav-list-item {
          // text-transform: uppercase;
          height: 30px;

          a {
            display: flex;
            align-items: center;
            width: 100%;
            white-space: nowrap;

            &.router-link-active {
              color: var(--theme-on-background);
              cursor: default;
            }
          }

          span {
            font-size: 1.5rem;
            display: inline-flex;
            margin-right: 5px;
            width: 30px;
            height: 100%;
            align-items: center;
          }
        }
      }

      footer {
        display: flex;
        width: 100%;

        justify-content: center;
        align-items: center;
      }

      &.expanded {
        width: 250px;

        footer {
          justify-content: flex-end;
        }
      }
    }

    aside ~ section {
      width: 100%;
      height: 100%;
      display: flex;
      flex: 1;
      overflow-y: auto;
    }
  }
}
</style>
