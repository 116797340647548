import Vue from 'vue';

import { VLazyImagePlugin } from 'v-lazy-image';

import App from './App.vue';
import router from './router';
import store from './store/index.js';

import AnalyticsPlugin from './plugins/AnalyticsPlugin';
import Lock from './plugins/LockService';

import patentNumber from './filters/patentNumber';

Vue.config.productionTip = false;

Vue.use(VLazyImagePlugin);
Vue.use(AnalyticsPlugin);
Vue.use(Lock);

Vue.filter('patentNumber', patentNumber);

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');
