import Vue from 'vue';
import Vuex from 'vuex';
import ui from './ui';
import identity from './identity';
import search from './search';
import patents from './patents';
import authentication from './authentication';
import chat from './chat';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: { ui, identity, search, patents, authentication, chat },
  state: {},
  mutations: {},
  actions: {}
});
