<template>
  <i :class="['material-icons', ...mdi]"></i>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    spin: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    mdi() {
      const classes = ['mdi', 'mdi-' + this.name];

      if (this.spin) {
        classes.push('mdi-spin');
      }
      return classes;
    }
  }
};
</script>

<style lang="scss" scoped>
.material-icons {
  font-style: normal;
}
</style>
