<template>
  <section>
    <div class="logo-wrapper">
      <img alt="Patent Searchr logo" src="./../assets/logo.svg" />
    </div>
    <header>
      <h1>Hey! Do we know you?</h1>
    </header>

    <form v-if="providers.includes('local')" class="provider" @submit.prevent="submit">
      <div>
        <ps-text-field v-model="email" :label="'Email'" />
      </div>
      <div>
        <ps-text-field v-model="password" :label="'Password'" type="password" />
      </div>
      <div v-if="isRequestFailed" class="error">Authentication failed. Check your email/password and try again.</div>
      <div class="form-row submit">
        <ps-button class="primary" :disabled="!isValid || isRequestPending">Let me in!</ps-button>
      </div>
    </form>
    <div v-if="providers.includes('microsoft')" class="provider external">
      <ps-button class="default microsoft" :disabled="isRequestPending" @click.stop.prevent="microsoft">Sign in with Microsoft</ps-button>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';

import Button from './common/Button';
import TextField from './common/TextField';
export default {
  components: {
    'ps-button': Button,
    'ps-text-field': TextField
  },
  data() {
    return {
      email: '',
      password: ''
    };
  },
  computed: {
    ...mapState({
      isRequestPending: (s) => s.identity.isRequestPending,
      isRequestFailed: (s) => s.identity.isRequestFailed,
      providers: (s) => s.authentication.providers
    }),
    isValid() {
      return this.email.length && this.password.length;
    }
  },
  async created() {
    await this.$store.dispatch('authentication/initialize');
  },
  methods: {
    async submit() {
      if (!this.isValid) {
        return;
      }

      await this.$store.dispatch('identity/login', { email: this.email, password: this.password });
    },
    async microsoft() {
      const response = await fetch(`/api/auth/microsoft?redirect_uri=${document.location.origin}/microsoft.html`);
      if (response.status < 200 || response.status > 300) {
        this.$toast.error({
          title: 'Failed to Sign in with Microsoft',
          message: `Please, try again later or contact our development team.`
        });
        return;
      }

      const url = await response.text();
      window.location.href = url;
    }
  }
};
</script>

<style lang="scss" scoped>
section {
  min-height: 100%;
  background-color: var(--theme-surface);

  header {
    width: 100%;
    padding: 1rem 0;
  }
  h1 {
    color: var(--theme-on-surface);
    text-align: center;
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }

  .logo-wrapper {
    text-align: center;
    height: 200px;
  }
  img {
    max-width: 200px;
    max-height: 200px;
  }

  form {
    max-width: 480px;
    margin: auto;
    padding: 2rem;

    > * {
      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }
      &.submit {
        display: flex;
        justify-content: center;
      }
    }

    .error {
      font-size: 0.8rem;
      color: var(--theme-error);
      text-align: center;
    }
  }

  .provider {
    &:not(:last-child) {
      &:not(:last-child) {
        margin-bottom: 2rem;
      }
      border-bottom: 2px solid var(--theme-highlight);
    }
  }
  .external {
    display: flex;
    justify-content: center;
    .microsoft {
      &:before {
        content: '';
        background-image: url('~./../assets/microsoft.svg');
        width: 21px;
        height: 21px;
        margin-right: 12px;
      }
      background: #2f2f2f;
      color: #fff;
      font-weight: 600;
      font-size: 15px;
      line-height: 1;
      text-transform: inherit;
      &:hover {
        background: #2f2f2f !important;
      }
    }
  }
}
</style>
