<template>
  <div v-if="currentRun.response.length" ref="container" class="stream-content" @scroll="onScroll">
    <div v-for="(message, index) in mappedResponse" :key="message.key">
      <div v-if="message.type === 'tool'" class="tool">
        <div class="header">
          <Button color="secondary" class="button" @click="toggleToll(message)">
            <div class="label">
              <MdIcon name="function-variant" size="sm" />
              <span class="label">{{ message.author }}</span>

              <MdIcon :name="message.expanded ? 'chevron-up' : 'chevron-down'" size="sm" />
            </div>
          </Button>
          <template v-if="!message.result">
            <div class="typing">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </template>
        </div>
        <div v-if="message.text" class="body" :class="{ expanded: message.expanded }">
          <div><span> ARGUMENTS:</span>{{ message.arguments }}</div>
          <div><span> PRESERVE IN CONVERSATION:</span> {{ !!message.cache }}</div>
          <div>
            <span> RESULT: </span><br />
            {{ message.text }}
          </div>
        </div>
      </div>
      <div v-else :class="message.author === 'CURRENT_USER' ? 'user' : 'model'" class="message">
        <span class="label" :title="message.system">
          <MdIcon v-if="message.author !== 'CURRENT_USER'" :name="responseType(message)" size="sm" />
          {{ message.author === 'CURRENT_USER' ? email : message.author }}
          <MdIcon v-if="message.retry && !message.error" title="retry" name="alert-circle-outline" color="warning" size="sm" />
          <MdIcon v-if="message.error" title="error" name="alert-circle-outline" color="error" size="sm" />
          <template v-if="isRequestPending && index === currentRun.response.length - 1">
            <div class="typing">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </template>
        </span>
        <div v-if="message.text && !message.html">
          <div class="body markdown" :class="{ retry: message.retry }" v-html="renderMarkdown(message.text)"></div>
        </div>
        <div v-else-if="message.html">
          <div class="body markdown" v-html="message.html.before"></div>
          <HtmlPreview :html-content="message.html.content" />
          <div class="body markdown" v-html="message.html.after"></div>
        </div>
      </div>
    </div>
    <div ref="scrollToMe"></div>
  </div>
  <div v-else-if="!isRequestPending" class="empty">
    <span> Type a message to start the conversation </span>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import MdIcon from '@/components/common/Icon';
import Button from '@/components/common/Button';
import { marked } from 'marked';
import HtmlPreview from './HtmlPreview.vue';

export default {
  components: {
    MdIcon,
    Button,
    HtmlPreview
  },
  data() {
    return {
      followScroll: true,
      programmaticScroll: false
    };
  },
  computed: {
    ...mapState({
      currentRun: (s) => s.chat,
      email: (s) => s.identity.email,
      isRequestPending: (s) => s.chat.isRequestPending
    }),
    mappedResponse() {
      return this.currentRun.response.map((r) => {
        const regexp = /^(?<before>[\s\S]*?)(?<html>```html[\s\S]*?<\/html>)(?<after>[\s\S]*)$/;
        if (!r.text) {
          return r;
        }
        const match = r.text.match(regexp);
        if (match && match[0]) {
          return {
            ...r,
            html: {
              content: match.groups.html.replace('```html\n', ''),
              before: marked(match.groups.before.trim()),
              after: marked(match.groups.after.trim())
            }
          };
        }
        return r;
      });
    }
  },
  watch: {
    isRequestPending(nv) {
      if (nv) {
        this.followScroll = true;
      }
    },
    'currentRun.response.length': {
      handler: async function () {
        if (!this.followScroll) {
          return;
        }

        await this.$nextTick();
        this.scrollToBottom();
      }
    },
    'currentRun.response': {
      handler: async function () {
        if (!this.followScroll) {
          return;
        }

        await this.$nextTick();
        this.scrollToBottom();
      },
      deep: true
    }
  },
  methods: {
    renderMarkdown(text) {
      return marked(text);
    },
    toggleToll(toolMessage) {
      toolMessage.expanded = !toolMessage.expanded;
    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (this.programmaticScroll) {
        this.resetProgrammaticScrollFlag();
        return;
      }

      if (scrollTop + clientHeight >= scrollHeight - 20) {
        this.followScroll = true;
      } else {
        this.followScroll = false;
      }
    },
    resetProgrammaticScrollFlag() {
      // Use a short delay to reset the flag
      setTimeout(() => {
        this.programmaticScroll = false;
      }, 100);
    },
    linkPrefixText(message) {
      const item = this.getLinkFromMessage(message);

      const indexInGroup = this.currentRun.chain.filter((l) => l.type === item.type).indexOf(item);
      return `${item.type.substring(0, 1).toUpperCase()}${indexInGroup + 1}`;
    },
    getLinkFromMessage(message) {
      return this.currentRun?.chain?.find((l) => l.name === message.name && l.type === message.type);
    },
    async next(message) {
      message.done = true;
      await this.$store.dispatch('prompts/sample/continue');
    },
    responseType(m) {
      if (m.type === 'template') {
        return 'code-block-braces';
      }

      if (m.type === 'query') {
        return 'database-search';
      }

      return 'robot-confused';
    },
    scrollToBottom() {
      const container = this.$refs.container;
      if (!container) {
        return;
      }

      this.programmaticScroll = true;
      container.scrollTop = container.scrollHeight;
    }
  }
};
</script>

<style lang="scss">
.markdown {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  h1 {
    font-size: 2.5em;
  }

  h2 {
    font-size: 2em;
  }

  h3 {
    font-size: 1.75em;
  }

  h4 {
    font-size: 1.5em;
  }

  h5 {
    font-size: 1.25em;
  }

  h6 {
    font-size: 1.1em;
  }

  // Параграфи
  p {
    margin-bottom: 10px;
    line-height: 1.8;
  }

  // Списки
  ul,
  ol {
    margin: 0;
    padding-left: 20px;
  }

  li {
    margin-bottom: 10px;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  // Ссилки
  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  // Блоки коду
  pre,
  code {
    font-family: 'Courier New', monospace;
    background-color: var(--theme-background);
    padding: 5px;
    border-radius: 4px;
  }

  pre {
    padding: 10px;
    overflow-x: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  // Цитати
  blockquote {
    border-left: 4px solid #ccc;
    padding-left: 15px;
    margin: 20px 0;
    font-style: italic;
  }

  // Зображення
  img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 20px 0;
  }

  // Лінії
  hr {
    border: 1px solid #ddd;
    margin: 30px 0;
  }
}
</style>

<style scoped lang="scss">
.empty {
  height: 100%;
  width: 100%;
  display: grid;
  align-items: center;
  justify-items: center;
  overflow-y: hidden;

  span {
    font-size: 1rem;
  }
}

.stream-content {
  overflow-y: scroll;
  overflow-x: hidden;
  white-space: pre-line;
  height: 100%;
  font-size: 12px;

  .form {
    padding: 0.75rem 1rem 0.75rem 1.25rem;
    display: grid;
    grid-template-rows: 1fr max-content;
    gap: 5px;

    label {
      text-transform: uppercase;
      font-weight: 500;
      padding: 2px;
    }

    .prefix {
      color: black;
      background-color: white;
      font-weight: 700;
      font-size: 16;
      border-radius: 2px;
    }

    .form-body {
      background-color: var(--theme-surface);
      padding: 15px;
      border-radius: 3px;

      span {
        user-select: none;
      }

      .variables {
        margin-top: 10px;
        border: 1px solid var(--theme-primary);
        padding: 7px;
        padding-bottom: 0;
        border-radius: 3px;
      }

      span {
        font-size: 14px;
      }
    }
  }

  .tool {
    padding: 0.75rem 1rem 0 1.25rem;
    padding-right: 15%;
    $dot-width: 5px;
    $dot-color: var(--theme-primary);
    $speed: 1.5s;

    .header {
      display: flex;
      align-items: center;
      gap: 3px;

      .typing {
        position: relative;

        span {
          content: '';
          animation: blink $speed infinite;
          animation-fill-mode: both;
          height: $dot-width;
          width: $dot-width;
          background: $dot-color;
          position: absolute;
          left: 0;
          top: 0;
          border-radius: 50%;

          &:nth-child(2) {
            animation-delay: 0.2s;
            margin-left: $dot-width * 1.5;
          }

          &:nth-child(3) {
            animation-delay: 0.4s;
            margin-left: $dot-width * 3;
          }
        }
      }

      @keyframes blink {
        0% {
          opacity: 0.1;
        }

        20% {
          opacity: 1;
        }

        100% {
          opacity: 0.1;
        }
      }

      .button {
        padding: 3px;
        align-items: center;
        align-self: center;
      }
    }

    .label {
      display: inline-flex;
      align-items: center;
      gap: 5px;
      font-size: 0.7rem;
      font-style: italic;
    }

    .body {
      display: none;
      background-color: var(--theme-surface);
      padding: 9px;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      text-align: left;
      line-height: 1.3rem;
      overflow-wrap: break-word;
      word-wrap: break-word;
      word-break: break-word;

      span {
        font-weight: 700;
      }

      &.retry {
        font-style: italic;
      }

      &.expanded {
        display: inline-block;
      }
    }
  }

  .message {
    padding: 0.75rem 1rem 0.75rem 1.25rem;
    padding-right: 15%;
    $dot-width: 5px;
    $dot-color: var(--theme-primary);
    $speed: 1.5s;

    .typing {
      position: relative;

      span {
        content: '';
        animation: blink $speed infinite;
        animation-fill-mode: both;
        height: $dot-width;
        width: $dot-width;
        background: $dot-color;
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 50%;

        &:nth-child(2) {
          animation-delay: 0.2s;
          margin-left: $dot-width * 1.5;
        }

        &:nth-child(3) {
          animation-delay: 0.4s;
          margin-left: $dot-width * 3;
        }
      }
    }

    @keyframes blink {
      0% {
        opacity: 0.1;
      }

      20% {
        opacity: 1;
      }

      100% {
        opacity: 0.1;
      }
    }

    &.user {
      text-align: right;
      padding-left: 25%;
      padding-right: 5px;
    }

    .label {
      display: inline-flex;
      align-items: center;
      gap: 5px;
      font-size: 0.7rem;
      font-style: italic;
      color: var(--theme-on-background-accent);
    }

    .body {
      display: inline-block;
      background-color: var(--theme-surface);
      padding: 9px;
      border-radius: 6px;
      text-align: left;
      line-height: 1.3rem;

      &.retry {
        font-style: italic;
      }
    }
  }
}
</style>
