<template>
  <header v-if="isAuthenticated">
    <div>
      <router-link v-if="logo" to="/">
        <img alt="Patent Searchr logo" src="./../assets/logo.svg" />
        <label>Patent Searchr</label>
      </router-link>
    </div>
    <div>
      <ps-search-box v-if="search" :compact="true" />
    </div>
    <div class="right">
      <ps-button @click="goToFeedback">Feedback</ps-button>
      <ps-button v-if="help" @click="toggleHelp">Help</ps-button>
      <ps-button @click="logout">Log out</ps-button>
    </div>
  </header>
  <header v-else>
    <div>
      <router-link v-if="logo" to="/">
        <img alt="Patent Searchr logo" src="./../assets/logo.svg" />
        <label>Patent Searchr</label>
      </router-link>
    </div>
    <div></div>
    <div class="right"></div>
  </header>
</template>

<script>
import { mapState } from 'vuex';
import httpClient from '@/utils/httpClient';

import SearchBox from './SearchBox';
import Button from './common/Button';

export default {
  components: {
    'ps-search-box': SearchBox,
    'ps-button': Button
  },
  props: {
    logo: {
      type: Boolean,
      default: true
    },
    search: {
      type: Boolean,
      default: true
    },
    help: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState({
      ui: (s) => s.ui,
      isAuthenticated: (s) => s.identity.isAuthenticated
    })
  },
  methods: {
    async goToFeedback() {
      const lock = this.$lock();
      const url = await httpClient.get('/api/feedback/canny/redirectUrl');
      window.open(url, '_blank');
      lock.release();
    },
    async toggleHelp() {
      if (this.ui.helpVisible) {
        this.$trackEvent('Help closed');
      } else {
        this.$trackEvent('Help opened');
      }
      await this.$store.dispatch('ui/toggleHelp');
    },
    async logout() {
      this.$store.dispatch('identity/logout');
      window.location.reload();
    }
  }
};
</script>

<style scoped lang="scss">
header {
  display: grid;
  grid-template-columns: 300px minmax(0, 1fr) 350px;
  grid-template-rows: 50px;
  align-items: center;
  color: var(--theme-on-surface);
  background-color: var(--theme-surface);
  z-index: 1;
  grid-gap: 10px;

  a {
    color: var(--theme-on-surface);
    text-decoration: none;
    cursor: pointer;
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: 10px;
    align-items: center;
    font-size: 1.5rem;
    font-family: 'Roboto Mono', monospace;
    padding: 0 2rem;

    &:visited,
    &:active,
    &:focus {
      color: var(--theme-on-surface);
      text-decoration: none;
    }

    img {
      max-width: 100%;
      max-height: 100%;
      height: 25px;
    }

    label {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    > * {
      cursor: pointer !important;
    }
  }

  .right {
    text-align: right;
    padding: 0 2rem;
  }
}
</style>
